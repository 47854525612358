/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-06",
    versionChannel: "nightly",
    buildDate: "2024-08-06T00:05:25.298Z",
    commitHash: "07c9901efe278ea228a5a80467efdbe40bf1c33f",
};
